import React from 'react';
import { Box } from '@mui/material';

const HomePanel = ({ children }) => {
    return (
        <Box
            component="main"
            sx={{
                position: 'absolute', // Use absolute positioning
                top: 0, // Align to the top of the parent
                left: 0, // Align to the left of the parent
                width: '100%', // Span the full width
                height: '100%', // Span the full height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center', // Center content vertically
                alignItems: 'center', // Center content horizontally
                bgcolor: 'secondary.dark' // Ensure background is transparent
            }}
        >
            {children}
        </Box>
    );
};

export default HomePanel;