import { createContext, useState, useMemo } from "react";
import { createTheme, alpha } from '@mui/material/styles';

// color design tokens
export const tokens = (mode) => ({
    ...(mode === 'dark'
    ? {
        neutral: {
            100: "#393730",
            90: "#555349",
            80: "#747268",
            70: "#928F82",
            60: "#A5A296",
            50: "#CAC5B5",
            40: "#E0DCCC",
            30: "#ECE7D4",
            20: "#EEEDDF",
            10: "#F2F1E8",
          },
        primary: {
            100: '#2D3B3D',
            90: "#3D5A5D",
            80: "#497A80",
            70: "#57969E",
            60: "#5BA2AA",
            50: "#6CB9C2",
            40: "#82D2DB",
            30: "#A6DFE4",
            20: "#B7E7EC",
            10: "#D0EDF0"
          },
        secondary: {
            100: "#20272C",
            90: "#303C45",
            80: "#455A69",
            70: "#506C7F",
            60: "#5D7C92",
            50: "#7492A9",
            40: "#8CA6B8",
            30: "#97B0C2",
            10: "#D2DEE7",
        },
        success: {
            100: "#2E5D09",
            90: "#468117",
            80: "#5F9C2E",
            70: "#78AC4D",
            60: "#8FC266",
            50: "#A1D775",
            40: "#A7EC70",
            30: "#B9FF82",
            20: "#CDFFA6",
            10: "#DEFFC3",
        },
        error: {
            100: "#570E18",
            90: "#811522",
            80: "#BF1A2F",
            70: "#DE3449",
            60: "#F94058",
            50: "#F95D71",
            40: "#FD7D8D",
            30: "#F6AAB3",
            20: "#FAC7CD",
            10: "#FFD7DC",
        },
        accent: {
            100: "#A83C00",
            90: "#C45200",
            80: "#D95B00",
            70: "#ED6300",
            60: "#FF6F00",
            50: "#FF891B",
            40: "#FFB03B",
            30: "#FFC75A",
            20: "#FFDF6D",
            10: "#FFE99D",
        }
    }
    : {
        neutral: {
            10: "#393730",
            20: "#555349",
            30: "#747268",
            40: "#928F82",
            50: "#A5A296",
            60: "#CAC5B5",
            70: "#E0DCCC",
            80: "#ECE7D4",
            90: "#EEEDDF",
            100: "#F2F1E8",
          },
        primary: {
            10: '#2D3B3D',
            20: "#3D5A5D",
            30: "#497A80",
            40: "#57969E",
            50: "#5BA2AA",
            60: "#6CB9C2",
            70: "#82D2DB",
            80: "#A6DFE4",
            90: "#B7E7EC",
            100: "#D0EDF0",
          },
        secondary: {
            10: "#20272C",
            20: "#303C45",
            30: "#455A69",
            40: "#506C7F",
            50: "#5D7C92",
            60: "#7492A9",
            70: "#8CA6B8",
            80: "#97B0C2",
            90: "#AEC2D0",
            100: "#D2DEE7",
        },
        success: {
            10: "#2E5D09",
            20: "#468117",
            30: "#5F9C2E",
            40: "#78AC4D",
            50: "#8FC266",
            60: "#A1D775",
            70: "#A7EC70",
            80: "#B9FF82",
            90: "#CDFFA6",
            100: "#DEFFC3",
        },
        error: {
            10: "#570E18",
            20: "#811522",
            30: "#BF1A2F",
            40: "#DE3449",
            50: "#F94058",
            60: "#F95D71",
            70: "#FD7D8D",
            80: "#F6AAB3",
            90: "#FAC7CD",
            100: "#FFD7DC",
        },
        accent: {
            10: "#A83C00",
            20: "#C45200",
            30: "#D95B00",
            40: "#ED6300",
            50: "#FF6F00",
            60: "#FF891B",
            70: "#FFB03B",
            80: "#FFC75A",
            90: "#FFDF6D",
            100: "#FFE99D",
        }
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === 'dark'
                ? {
                    primary: {
                        main: colors.primary[30],
                        light: colors.primary[40],
                        dark: colors.primary[60],
                    },
                    secondary: {
                        main: colors.secondary[50],
                        dark: colors.secondary[40],
                        light: colors.secondary[80],
                        container: colors.secondary[90]
                    },
                    accent: {
                        main: colors.accent[40],
                        dark: colors.accent[20],
                        light: colors.accent[60],
                    },
                    neutral: {
                        main: colors.neutral[50],
                        dark: colors.neutral[30],
                        light: colors.neutral[80]
                    },
                    success: {
                        main: colors.success[50],
                        dark: colors.success[10],
                        light: colors.success[20],
                        container: alpha(colors.success[100], 0.25)
                    },
                    error: {
                        main: colors.error[10],
                        dark: colors.error[20],
                        light: colors.error[90],
                        container: colors.error[100]
                    },
                    
                    background: {
                        default: colors.neutral[100],
                        paper: colors.neutral[90],
                        dark: colors.neutral[80],
                    },
                    text: {
                        primary: colors.neutral[30],
                        secondary: colors.neutral[60],
                        disabled: colors.neutral[70],
                        hint: colors.neutral[70],
                    },
                    divider: alpha(colors.neutral[60], 0.5),      
              } : {
                    primary: {
                        main: colors.primary[50],
                        dark: colors.primary[30],
                        light: colors.primary[80],
                    },
                    secondary: {
                        main: colors.secondary[50],
                        dark: colors.secondary[30],
                        light: colors.secondary[80],
                        container: colors.secondary[90]
                    },
                    accent: {
                        main: colors.accent[50],
                        dark: colors.accent[30],
                        light: colors.accent[80],
                    },
                    neutral: {
                        main: colors.neutral[50],
                        dark: colors.neutral[30],
                        light: colors.neutral[80]
                    },
                    background: {
                        default: colors.neutral[60],
                        paper: colors.neutral[100],
                        dark: colors.neutral[60]
                    },
                    
                    success: {
                        main: colors.success[50],
                        dark: colors.success[30],
                        light: colors.success[70],
                    },
                    text: {
                        primary: colors.neutral[10],
                        secondary: colors.neutral[30],
                        disabled: colors.neutral[60],
                        hint: colors.neutral[60],
                    },
                    info: {
                        main: colors.secondary[50],
                        dark: colors.secondary[30],
                        light: colors.secondary[70],
                    },
                    divider: colors.neutral[30],                    
                }),
            },
        typography: {
            fontFamily: ['"Vast Shadow", "Mohave", "Bungee", "Raleway", "Nunito Sans", "sofia-pro", sans-serif'].join(","),

            h1: {
                fontFamily: ["Mohave"],
                fontSize: 28,
                letterSpacing: "0.02em",
            },
            h2: {
                fontFamily: ["Raleway"],
            },
            h3: {
                fontFamily: ["Raleway"],
                fontWeight: 300,
            },
            h4: {
                fontFamily: ["Raleway"],
                fontWeight: 500,
            },
            h5: {
                fontFamily: ["Raleway"],
                fontSize: 18,
                lineHeight: 1.5,
                fontWeight: 500,
            },
            h6: {
                fontFamily: ["Raleway"],
                fontSize: 16,
                lineHeight: 1.25,
                fontWeight: 500
            },
            subtitle1: {
                fontFamily: ["Raleway"],
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.5,
            },
            subtitle2: {
                fontFamily: ["Raleway"],
                fontWeight: 400,
                fontSize: 16,
                lineHeight: 1.5,
            },
            caption: {
                fontFamily: ["Raleway"],
                fontWeight: 400,
            },
            captionLarge: {
                fontFamily: ["Raleway"],
                fontWeight: 700,
                fontSize: "32px"
            },
            labelLarge: {
                fontFamily: ["Raleway"],
                fontSize: "16px",
                fontWeight: 500,
                display: "block",
                textTransform: "uppercase",
                letterSpacing: "0.1em",
            },
            meta: {
                fontFamily: ["Raleway"],
                fontSize: "12px",
                fontWeight: 400,
                display: "block",
                textTransform: "uppercase",
            },
            title1: {
                fontFamily: ["Raleway"],
                fontSize: "24px",
                lineHeight: 1.5,
                display: "block",
            },
            title2: {
                fontFamily: ["Raleway"],
                fontSize: "24px",
                lineHeight: 1.5,
                fontWeight: 500,
            },
            body1: {
                fontFamily: ["Raleway"],
                fontSize: "16px",
                lineHeight: 1.625,
                display: "block",
                fontWeight: 400,
            },
            body2: {
                fontFamily: ["Raleway"],
                fontSize: "14px",
                lineHeight: 1.625,
                display: "block",
                fontWeight: 400,
            },
            body3: {
                fontFamily: ["Nunito Sans"],
                fontSize: "21px",
                lineHeight: 1.5,
                display: "block",
            },
            display1: {
                fontFamily: ["Vast Shadow"],
                letterSpacing: "-0.01em",
            },
            display2: {
                fontFamily: ["Mohave"],
                fontWeight: 700,
            },
            display3: {
                fontFamily: ["Bungee"],
            },
            display4: {
                fontFamily: ["Mohave"],
                fontWeight: 500,
            },  
            display5: {
                fontFamily: ["sofia-pro"],
            },
        },
        components: {
            MuiChip: {
              styleOverrides: {
                root: ({ theme }) =>
                  theme.unstable_sx({
                    px: 1,
                    py: 0.25,
                    borderRadius: 1,
                  }),
                label: {
                  padding: 'initial',
                  color: colors.neutral[10],
                },
                colorSuccess: {  
                    backgroundColor: colors.success[90],
                    borderColor: colors.success[80],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                },
                colorError: {  
                    backgroundColor: colors.error[100],
                    borderColor: colors.error[90],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                },
                colorInfo: {  
                    backgroundColor: colors.secondary[100],
                    borderColor: colors.secondary[90],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                },
                colorSecondary: {  
                    backgroundColor: colors.secondary[100],
                    borderColor: colors.secondary[90],
                    borderWidth: '1px',
                    borderStyle: 'solid',
                },
                icon: ({ theme }) =>
                  theme.unstable_sx({
                    mr: 0.5,
                    ml: '-2px',
                    color: colors.neutral[10],
                  }),
              },
            },
            MuiCard: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        borderRadius: 2,
                        elevation: 0
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 99,
                        textTransform: "none",
                        fontFamily: "Raleway", // Set the button font family
                        fontSize: "14px", // Set the button font size
                        fontWeight: 700,
                        padding: "4px 16px",
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        justifyContent: "flex-end",
                        padding: '0 16px 16px'
                    },
                },
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        padding: '24px 24px 16px'
                    }
                }
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: '16px 24px 16px'
                    }
                }
            },
            MuiAlert: {
                styleOverrides: {
                    root: {
                        '&.MuiAlert-standardInfo': {
                            backgroundColor: colors.secondary[100],
                            borderColor: colors.secondary[90],
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: '4px',
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: colors.primary[50], // Default link color
                        '&:hover': {
                            color: colors.primary[70], // Hover link color
                        },
                    },
                },
            },
           
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1400,
            },
        },
        props: {
            MuiContainer: {
                maxWidth: "xl",
            },
        },
    };
};

// context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => {},
});

export const useMode = () => {
    const [mode, setMode] = useState("light");

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),
        []
    );
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode];
};