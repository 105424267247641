import React, { useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const twinkle = keyframes`
  0%, 100% { opacity: 0.2; }
  50% { opacity: 1; }
`;

const Dot = styled.div`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: white;
  border-radius: 50%;
  animation: ${twinkle} ${({ duration }) => duration} infinite ease-in-out;
`;

const RandomDot = () => {
    const [top] = useState(`${Math.random() * 100}vh`);
    const [left] = useState(`${Math.random() * 100}vw`);
    const [width] = useState(`${Math.random() * 5 + 1}px`);
    const [height] = useState(`${Math.random() * 5 + 1}px`);
    const [duration] = useState(`${Math.random() * 2 + 1}s`);

    return (
        <Dot top={top} left={left} width={width} height={height} duration={duration} />
    );
};

const TwinklingStars = ({ count = 100 }) => {
  return (
    <>
      {Array.from({ length: count }, (_, index) => (
        <RandomDot key={index} />
      ))}
    </>
  );
};

export default TwinklingStars;