import React, { useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import * as THREE from 'three';
import { useTheme } from '@mui/material/styles'; // Import the useTheme hook

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const moveUpWithDip = keyframes`
    0% { top: 50%; }
    20% { top: 55%; }  /* Quick dip downwards */
    100% { top: -5%; } /* Final position with top half cropped offscreen */
`;

const SphereContainer = styled('div')`
    width: 420px;
    height: 420px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${fadeIn} 3s, ${moveUpWithDip} 2s 1s forwards;
    z-index: 10; /* Ensure this is higher than other elements */

`;

const WireframeSphere = ({ colorToken = 'secondary.main' }) => {
    const mountRef = useRef(null);
    const sphereRef = useRef(null);
    const rotationSpeedRef = useRef(0.01);

    const theme = useTheme();
    const color = theme.palette[colorToken.split('.')[0]][colorToken.split('.')[1]];

    console.log(`Color token: ${colorToken}, Retrieved color: ${color}`);

    // Setup and animation logic
    useEffect(() => {
        const mount = mountRef.current;
        const width = mount.clientWidth;
        const height = mount.clientHeight;

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(width, height);
        mount.appendChild(renderer.domElement);

        // Add lighting to the scene
        const ambientLight = new THREE.AmbientLight(0xffffff, .8);
        scene.add(ambientLight);

        const pointLight = new THREE.PointLight(0xffffff, 1);
        pointLight.position.set(-24, 0, 4);
        scene.add(pointLight);

        const geometry = new THREE.SphereGeometry(2.44, 24, 24);

        // Create the solid color material
        const solidMaterial = new THREE.MeshStandardMaterial({
            color: color,
            transparent: true,
            opacity: 0.72, // Adjust the opacity value to your liking
        });

        // Create the wireframe material
        const wireframeMaterial = new THREE.MeshBasicMaterial({
            color: 'beige',
            wireframe: true,
        });

        // Create the solid mesh
        const solidMesh = new THREE.Mesh(geometry, solidMaterial);

        // Create the wireframe mesh
        const wireframe = new THREE.WireframeGeometry(geometry);
        const wireframeMesh = new THREE.LineSegments(wireframe, wireframeMaterial);

        // Create a group and add both meshes to it
        const sphereGroup = new THREE.Group();
        sphereGroup.add(solidMesh);
        sphereGroup.add(wireframeMesh);

        // Add the group to the scene
        scene.add(sphereGroup);

        sphereRef.current = sphereGroup;

        camera.position.z = 4;

        const animate = () => {
            requestAnimationFrame(animate);
            if (sphereRef.current) {
                sphereRef.current.rotation.y += rotationSpeedRef.current;
            }
            renderer.render(scene, camera);
        };

        animate();

        return () => {
            cancelAnimationFrame(animate);
            mount.removeChild(renderer.domElement);
        };
    }, [color]);



    // Wind-up and slow-down logic
    useEffect(() => {
        let startTime;
        const windUpDuration = 3000; // 1 second for wind-up
        const mediumSpeedDuration = 2000; // 2 seconds for medium speed
        const slowDownDuration = 1000; // 1 second for slow-down

        const windUpSpin = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;

            if (elapsed < windUpDuration) {
                // Wind-up phase
                const progress = elapsed / windUpDuration;
                rotationSpeedRef.current = .005 + progress * (0.10);
                requestAnimationFrame(windUpSpin);
            } else if (elapsed < windUpDuration + mediumSpeedDuration) {
                // Medium speed phase
                rotationSpeedRef.current = .005;
                requestAnimationFrame(windUpSpin);
            } else if (elapsed < windUpDuration + mediumSpeedDuration + slowDownDuration) {
                // Slow-down phase
                const progress = (elapsed - windUpDuration - mediumSpeedDuration) / slowDownDuration;
                rotationSpeedRef.current = .01 - progress * (.05);
                requestAnimationFrame(windUpSpin);
            } else {
                // Final slow speed
                rotationSpeedRef.current = 0.005; // Maintain slow pace
            }
        };


        requestAnimationFrame(windUpSpin);
    }, []);

    return (
        <SphereContainer>
            <div ref={mountRef} style={{ width: '100%', height: '100%' }} />
        </SphereContainer>
    );
};

export default WireframeSphere;