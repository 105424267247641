import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ColorModeContext, useMode } from './styles/theme'; // Ensure this is correctly imported
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import Resume from './pages/Resume'; // Import the new Resume page

function App() {
  const [theme, colorMode, mode] = useMode();

  return (
    <ColorModeContext.Provider value={{ toggleColorMode: colorMode.toggleColorMode, mode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Layout toggleTheme={colorMode.toggleColorMode}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/resume" element={<Resume />} />
              {/* Define other routes */}
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
