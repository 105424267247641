// src/pages/Resume.js

import React from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import ContentPanel from '../components/PageComponents/ContentPanel';
import { useTheme, alpha } from '@mui/material/styles';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Import the PDF icon
import resume from '../assets/nathan-henry_resume_2024.pdf'; // Import the PDF file

const Resume = () => {
    const theme = useTheme();

    return (
            <ContentPanel>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row', // Change to row to place card on the right
                    justifyContent: 'center',
                    alignItems: 'center', // Align items to the top
                    textAlign: 'left',
                    width: '100%',
                    padding: '16px' // Optional: Add padding for better spacing
                }}>
                    <Box sx={{ 
                        flex: 1,
                        maxWidth: '640px',
                        transform: 'translateX(-25%)',

                    }}>

                        <Box mt={16} color={theme.palette.text.primary}>

                            <Box sx={{ 
                                backgroundColor: theme.palette.secondary.dark,
                                borderRadius: '2px', // Optional: rounded corners
                                display: 'inline-block', // Make the box width driven by content
                                padding: '7px 8px 0px', // Even padding all around

                            }}>
                                <Typography variant="h1" textTransform={"uppercase"} fontWeight={300} color={alpha(theme.palette.secondary.container, 1)} >
                                    Resume
                                </Typography>
                            </Box>


                            <Box mb={8} mt={6}> 
                                <Typography variant="body1" paragraph>
                                    <span style={{
                                        fontWeight: '700'
                                    }}>
                                        A forward-thinking Creative Technologist</span>, I blend imagination with technical expertise to drive innovation in product design.
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    My career began in game design, where I honed my UI/UX design skills, setting the stage for my successful tenure as the owner of a design consultancy. Later, shifting towards engaging educational design, I led teams at College Board and Penn Foster, integrating hands-on technical expertise with executive leadership.
                                </Typography>
                                <Typography variant="body1" paragraph>
                                    My approach is to remain actively involved in the design and technical aspects of the work, ensuring the delivery of high-quality, impactful products.
                                </Typography>
                            </Box>

                            <Typography variant="labelLarge" mb={4} color={theme.palette.secondary.main} gutterBottom>
                                Career Experience
                            </Typography>

                            <Box mb={8}>
                                <Typography variant="h5">
                                    Head of Design Systems
                                </Typography>
                                <Typography variant="subtitle1" color={theme.palette.text.secondary} mb={2}>
                                    Penn Foster | 07/21 - Current
                                </Typography>
                                <ul style={{ padding: 0, margin: 0, listStyleType: 'square' }}> {/* Customize list style here */}
                                    <li style={{ marginLeft: '20px' }}> {/* Adjust margin as needed */}
                                        <Typography variant="body1" mb={2}>
                                            Engineered and deployed a comprehensive design system and React component library, streamlining workflow and driving the on-schedule launch of the Learner Center student dashboard.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Championed a product roadmap for 2024-2026, spearheading infrastructure modernization and integrating a career-centric adaptive learning strategy.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Formulated an advanced skills, assessment, and credentialing model, scaling content delivery for faster alignment between instructional products and market demands.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1">
                                            Revolutionized team dynamics by integrating a 'scrum' process, syncing with engineering sprints, enhancing cross-functional training, and establishing a transparent reporting system for optimal alignment with organizational goals.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>

                            <Box mb={8}>
                                <Typography variant="h5">
                                    Lead UX Designer (Consultant)
                                </Typography>
                                <Typography variant="subtitle1" color={theme.palette.text.secondary} mb={2}>
                                    Disney Music Group | 01/21 - 07/21
                                </Typography>
                                <ul style={{ padding: 0, margin: 0, listStyleType: 'square' }}> {/* Customize list style here */}
                                    <li style={{ marginLeft: '20px' }}> 
                                        <Typography variant="body1" mb={2}>
                                            Revitalized Disney Music Group's UI/UX design framework, extending the design system to support new features in music catalog and payment tools.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}> 
                                        <Typography variant="body1" mb={2}>
                                            Innovatively applied React and Typescript in prototype development, enhancing the engineering process and ensuring seamless design-to-development transitions.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}> 
                                        <Typography variant="body1">
                                            Made pivotal contributions to user engagement and system efficiency, enabling the Product team to strategically plan future roadmaps based on user-centric designs.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>

                            <Box mb={8}>
                                <Typography variant="h5">
                                    Sr Director, User Experience Design
                                </Typography>
                                <Typography variant="subtitle1" color={theme.palette.text.secondary} mb={2}>
                                    The College Board | 07/13 - 12/20
                                </Typography>
                                <ul style={{ padding: 0, margin: 0, listStyleType: 'square' }}> 
                                    <li style={{ marginLeft: '20px' }}> 
                                        <Typography variant="body1" mb={2}>
                                            Transformed the Brand Experience department from a small unit into a powerhouse of 65 professionals, integrating diverse design disciplines under one roof.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Implemented an enterprise-wide design system, leveraging atomic design principles, which resulted in $20M cost savings in 18 months.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Drove the design of a mobile application for SAT preparation, achieving over 8 million downloads and significantly improving student engagement and test preparation outcomes.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Pioneered a partnership with Khan Academy, creating a personalized SAT practice platform, which led to an average score increase of 115 points among users.
                                        </Typography> 
                                    </li>    
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Enhanced web user experience through a global UX architecture, significantly improving key web conversions and user satisfaction metrics.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>

                            <Box mb={8}>
                                <Typography variant="h5">
                                    Game Designer
                                </Typography>
                                <Typography variant="subtitle1" color={theme.palette.text.secondary} mb={2}>
                                    This is Pop | 06/05 - 01/11
                                </Typography>
                                <ul style={{ padding: 0, margin: 0, listStyleType: 'square' }}> {/* Customize list style here */}
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Spearheaded creative and technical aspects of game design, producing numerous successful projects for top entertainment clients.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Directed the art and design for Adult Swim's 'Bible Fight', achieving over 5 million plays and earning recognition, including a Webby award nomination.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Consistently delivered high-quality, engaging designs, setting a standard in interactive media and game design within the entertainment industry.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>

                            <Box mb={8}>
                                <Typography variant="h5">
                                    Principal, Creative Director
                                </Typography>
                                <Typography variant="subtitle1" color={theme.palette.text.secondary} mb={2}>
                                    Nathan Henry Design | 01/07 - 01/12
                                </Typography>
                                <ul style={{ padding: 0, margin: 0, listStyleType: 'square' }}> {/* Customize list style here */}
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Founded and led a dynamic design studio, focusing on interaction and way-finding design for high-profile clients.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Developed and launched multimedia campaigns and interactive content, notably for Scholastic’s 'The 39 Clues' series, enhancing brand engagement and audience reach.
                                        </Typography>
                                    </li>
                                    <li style={{ marginLeft: '20px' }}>
                                        <Typography variant="body1" mb={2}>
                                            Successfully navigated complex design projects for Penguin Publishing and other prestigious clients, demonstrating versatility and creative excellence.
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>
                        </Box>

                        <Box mt={8} mb={8}>
                            <Typography variant="labelLarge" mb={4} color={theme.palette.secondary.main}>
                                Skills
                            </Typography>
                            <Box component="ul" sx={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                                <Box component="li" sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                                    <Box sx={{ 
                                        width: '1px', 
                                        height: '24px', 
                                        backgroundColor: theme.palette.primary.main, 
                                        marginRight: '16px' 
                                    }} />
                                    <Typography variant="body1" mb={2} color={theme.palette.text.primary}>
                                        <strong>Deep expertise in UI/UX design</strong>, with a focus on creating large-scale UI design systems.
                                    </Typography>
                                </Box>
                                <Box component="li" sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                                    <Box sx={{ 
                                        width: '1px', 
                                        height: '24px', 
                                        backgroundColor: theme.palette.primary.main, 
                                        marginRight: '16px' 
                                    }} />
                                    <Typography variant="body1" mb={2} color={theme.palette.text.primary}>
                                        <strong>Proficiency in React and Python</strong>, leveraging these technologies to enhance design and functionality through robust prototyping.
                                    </Typography>
                                </Box>
                                <Box component="li" sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                                    <Box sx={{ 
                                        width: '1px', 
                                        height: '24px', 
                                        backgroundColor: theme.palette.primary.main, 
                                        marginRight: '16px' 
                                    }} />
                                    <Typography variant="body1" mb={2} color={theme.palette.text.primary}>
                                        <strong>Strong capabilities in prompt engineering</strong>, utilizing AI/LLM technologies to innovate and streamline processes.
                                    </Typography>
                                </Box>
                                <Box component="li" sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                                    <Box sx={{ 
                                        width: '1px', 
                                        height: '24px', 
                                        backgroundColor: theme.palette.primary.main, 
                                        marginRight: '16px' 
                                    }} />
                                    <Typography variant="body1" mb={2} color={theme.palette.text.primary}>
                                        <strong>Adept in research and strategy</strong>, recently leading a comprehensive product roadmapping effort for 2024-2026.
                                    </Typography>
                                </Box>
                                <Box component="li" sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                                    <Box sx={{ 
                                        width: '1px', 
                                        height: '24px', 
                                        backgroundColor: theme.palette.primary.main, 
                                        marginRight: '16px' 
                                    }} />
                                    <Typography variant="body1" mb={2} color={theme.palette.text.primary}>
                                        <strong>Proven experience in developing content architecture</strong>, having developed instructional content strategies and implementing adaptive learning strategies.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>                    

                    </Box>
                    <Box sx={{ 
                        minWidth: '240px', 
                        ml: 8, 
                        position: 'fixed',
                        top: '50%',
                        right: '48px',
                        transform: 'translateY(-50%)' 
                    }}>
                        <Card width={240} sx={{
                            backgroundColor: alpha(theme.palette.background.default, 0.8),
                        }}> 
                            <CardContent sx={{ pb: 0 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                        <Box>
                                            <Typography variant="display1">
                                                Download Resume
                                            </Typography>
                                            <Typography variant="body2">
                                                PDF (500KB)
                                            </Typography>
                                        </Box>  
                                    </Box>
                                </Box>
                            </CardContent>
                            <CardActions sx={{ justifyContent: 'flex-end' }}>
                                <Button variant="contained" disableElevation size="small" component="a" href={resume} download="nathan-henry_resume_2024.pdf">
                                    Download
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Box>

            </ContentPanel>
        
    );
};

export default Resume;
