import React from 'react';
import { Textfit } from 'react-textfit';
import { Typography } from '@mui/material';

const highlightText = (text, highlight, highlightColor) => {
    if (!highlight) return text;

    const regex = new RegExp(`(${highlight.join('|')})`, 'gi');
    return text.replace(regex, (match) => `<span style="color: ${highlightColor}">${match}</span>`);
};

const FitTypography = ({ text, variant, color, lineHeight, highlight, highlightColor, style }) => {
    const highlightedText = highlightText(text, highlight, highlightColor);

    return (
        <Textfit
            mode="single"
            min={10}
            max={200}
            forceSingleModeWidth={true}
            style={{
                textTransform: 'uppercase',
                color: color,
                width: '100%',
                display: 'block',
                height: 'fit-content',
                lineHeight: lineHeight,
                ...style
            }}
        >
            <Typography
                variant={variant}
                component="div"
                dangerouslySetInnerHTML={{ __html: highlightedText }}
            />
        </Textfit>
    );
};

export default FitTypography;