import React from 'react';
import LeftMenuDrawer from './LeftMenu/LeftMenuDrawer';
import TopMenu from './TopMenu/TopMenu';
import { Box } from '@mui/material';


const Layout = ({ children, toggleTheme }) => {
    return (
        <>
            <TopMenu toggleTheme={toggleTheme} />
            <Box display="flex">
                <LeftMenuDrawer />
                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    minHeight: '100vh',
                }}>
                    {children}
                </Box>
            </Box>
        </>
    );
};

export default Layout;