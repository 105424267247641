import React, { useEffect, useState, useRef } from 'react';
import { Typography, Box, useTheme } from '@mui/material';

const AutoFitTypography = ({ lines, targetWidth = 224, lineHeight = 1.0, variant = 'body1', sx = {} }) => {
  const [fontSizes, setFontSizes] = useState([]);
  const canvasRef = useRef(document.createElement('canvas'));
  const theme = useTheme();

  useEffect(() => {
    const context = canvasRef.current.getContext('2d');
    const calculateFontSizes = () => {
      const sizes = lines.map(line => {
        let fontSize = 100; // Start with a large font size
        const fontSettings = theme.typography[variant];
        const letterSpacing = parseFloat(fontSettings.letterSpacing) || 0;

        // Function to measure text width including letter spacing
        const measureTextWidth = (text, fontSize) => {
          context.font = `${fontSize}px ${fontSettings.fontFamily.join(', ')}`;
          const textWidth = context.measureText(text).width;
          return textWidth + (text.length - 1) * letterSpacing * fontSize;
        };

        // Adjust for uppercase transformation
        const adjustedLine = line.toUpperCase();
        while (measureTextWidth(adjustedLine, fontSize) > targetWidth && fontSize > 0) {
          fontSize -= 1;
        }

        console.log(`Line: ${line}, FontSize: ${fontSize}px, MeasuredWidth: ${measureTextWidth(adjustedLine, fontSize)}px`);
        return fontSize;
      });

      setFontSizes(sizes);
    };

    calculateFontSizes();
  }, [lines, targetWidth, theme.typography, variant]);

  return (
    <Box>
      {lines.map((line, index) => (
        <Typography 
          key={index} 
          style={{ 
            fontSize: `${fontSizes[index]}px`, 
            lineHeight: lineHeight, 
            whiteSpace: 'nowrap', 
            textTransform: 'uppercase',  // Ensure textTransform is applied
            ...theme.typography[variant],
            ...sx 
          }}
        >
          {line}
        </Typography>
      ))}
    </Box>
  );
};

export default AutoFitTypography;