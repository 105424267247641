import React, { useEffect, useState, useContext } from 'react';
import HomePanel from '../components/PageComponents/HomePanel'; // Adjust the path as necessary
import { Typography, useTheme, Box } from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import CloudLeft from '../assets/cloudLeft'; // Adjust the path according to your directory structure
// import cloud1b from '../assets/cloud3b.png'; // Adjust the path according to your directory structure
import cloud2 from '../assets/cloud2.png'; // Adjust the path according to your directory structure
import { ColorModeContext } from '../styles/theme'; // Adjusted path to theme.js
import FitTypography from '../components/Animated/FitTypography';
import TwinklingStars from '../components/Animated/TwinklingStars';
import WireframeSphere from '../components/Animated/SphereComponent';
import SphereContainer from '../components/Animated/SphereComponent';


const AnimatedCloudLeft = styled(CloudLeft)`
    position: absolute;
    top: -16px;
    left: -100%;
    width: 528px;
    z-index: 1;
    height: auto;
    animation: slideInFromLeft 4s ease-out 3s forwards;
    animation-delay: 8s;

    @keyframes slideInFromLeft {
        0% { left: -100%; opacity: 0; }
        100% { left: -96px; opacity: 1; }
    }
`;

const CloudFromRight = styled.img`
    position: absolute;
    bottom: -48px;
    right: -100%;  // Start completely off-screen
    width: 528px;
    height: auto;
    opacity: 0;
    animation: slideInFromRight 4s ease-out 4s forwards;
    animation-delay: 8.5s;  // Delay the start of the animation

    @keyframes slideInFromRight {
        0% { right: -100%; opacity: 0; }
        100% { right: -120px; opacity: 1; }
    }
`;

// const FitTypography = ({ text, variant, color, lineHeight, highlight, highlightColor, style }) => {
//     const highlightedText = highlightText(text, highlight, highlightColor);

//     return (
//         <Textfit
//             mode="single"
//             min={10} // Set a minimum font size
//             max={200} // Set a maximum font size
//             forceSingleModeWidth={true}
//             style={{
//                 textTransform: 'uppercase',
//                 color: color,
//                 width: '874px', // Fixed width
//                 display: 'block',
//                 height: 'fit-content', // Adjust height to fit content
//                 lineHeight: lineHeight, // Custom line height
//                 ...style // Apply custom styles for animation
//             }}
//         >
//             <Typography
//                 variant={variant}
//                 component="div"
//                 sx={{ lineHeight }}
//                 dangerouslySetInnerHTML={{ __html: highlightedText }}
//             />
//         </Textfit>
//     );
// };

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const AnimatedSpan = styled.span`
    opacity: 0;
    animation: ${fadeIn} 0.5s forwards;
    margin-right: ${({ spacing }) => spacing}px;  // Use a prop to set the margin
`;

const StaggeredFadeTypography = ({ text, variant, color, lineHeight, style, spacing = 2, initialDelay = 0 }) => {
    const chars = text.split('').map((char, index) => (
        <AnimatedSpan key={index} spacing={spacing} style={{
            animationDelay: `${initialDelay + 0.1 * index}s`
        }}>
            {char}
        </AnimatedSpan>
    ));

    return (
        <Typography
            variant={variant}
            component="div"
            sx={{ lineHeight, ...style, display: 'inline-block' }}
            style={{ color: color }}
        >
            {chars}
        </Typography>
    );
};

// const Sphere = styled('div')`
//     width: 420px;
//     height: 420px;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     animation: fadeIn 2s ease-in-out, moveUpWithDip 2s ease-out 3s forwards;

//     @keyframes fadeIn {
//         from { opacity: 0; }
//         to { opacity: 1; }
//     }

//     @keyframes moveUpWithDip {
//         0% { top: 50%; }
//         20% { top: 55%; }  /* Quick dip downwards */
//         100% { top: -5%; } /* Final position with top half cropped offscreen */
//     }
// `;

// const WireframeSphere = ({ color }) => {
//     const mountRef = useRef(null);
//     const sphereRef = useRef(null);
//     const [rotationSpeed, setRotationSpeed] = useState(0.01); // Initial slow speed

//     // Setup and animation logic
//     useEffect(() => {
//         const mount = mountRef.current;
//         const width = mount.clientWidth;
//         const height = mount.clientHeight;

//         const scene = new THREE.Scene();
//         const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
//         const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
//         renderer.setSize(width, height);
//         mount.appendChild(renderer.domElement);

//         const geometry = new THREE.SphereGeometry(2.44, 9, 9);
//         const material = new THREE.LineBasicMaterial({ color });
//         const wireframe = new THREE.WireframeGeometry(geometry);
//         const sphere = new THREE.LineSegments(wireframe, material);
//         sphere.computeLineDistances();

//         sphereRef.current = sphere;
//         scene.add(sphere);

//         camera.position.z = 4;

//         const animate = () => {
//             requestAnimationFrame(animate);
//             if (sphereRef.current) {
//                 sphereRef.current.rotation.y += rotationSpeed;
//             }
//             renderer.render(scene, camera);
//         };

//         animate();

//         return () => {
//             cancelAnimationFrame(animate);
//             mount.removeChild(renderer.domElement);
//         };
//     }, []); // Removed color from dependencies to avoid re-initialization

//     // Handle color updates separately
//     useEffect(() => {
//         if (sphereRef.current) {
//             sphereRef.current.material.color.set(color);
//         }
//     }, [color]);

//     // Wind-up and slow-down logic
//     useEffect(() => {
//         let startTime;
//         const duration = 1000; // 1 second for wind-up
//         const slowDownDuration = 500; // 0.5 seconds for slow-down

//         const windUpSpin = (timestamp) => {
//             if (!startTime) startTime = timestamp;
//             const elapsed = timestamp - startTime;
//             if (elapsed < duration) {
//                 const progress = elapsed / duration;
//                 setRotationSpeed(0.01 + progress * (0.99));
//                 requestAnimationFrame(windUpSpin);
//             } else if (elapsed < duration + slowDownDuration) {
//                 const progress = (elapsed - duration) / slowDownDuration;
//                 setRotationSpeed(1 - progress * (0.9));
//                 requestAnimationFrame(windUpSpin);
//             } else {
//                 setRotationSpeed(0.1); // Maintain medium pace
//             }
//         };

//         requestAnimationFrame(windUpSpin);
//     }, []);

//     return <div ref={mountRef} style={{ width: '100%', height: '100%' }} />;
// };

const ShootingStar = styled.div`
    position: absolute;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    width: 2px;
    height: 100px;
    background: linear-gradient(white, transparent);
    transform: rotate(45deg);
    opacity: 0;
    animation: shooting 1s ease-in-out forwards;

    @keyframes shooting {
        0% {
            transform: translate(0, 0) rotate(45deg);
            opacity: 1;
        }
        100% {
            transform: translate(500px, -500px) rotate(45deg);
            opacity: 0;
        }
    }
`;

const RandomShootingStar = () => {
    const [top] = useState(`${Math.random() * 100}vh`);
    const [left] = useState(`${Math.random() * 100}vw`);

    return <ShootingStar top={top} left={left} />;
};

  
const HomePage = () => {
    const theme = useTheme();
    const { mode } = useContext(ColorModeContext);
    const color = theme.palette.background.paper;
    const highlightColor = theme.palette.primary.main; // Highlight color
    // const cloudSrc = mode === 'dark' ? cloud1b : CloudLeft;
    const [loaded, setLoaded] = useState(false);
    const [animateText, setAnimateText] = useState(false);
    const [shootingStars, setShootingStars] = useState([]);

    console.log("Current mode:", mode);

    useEffect(() => {
        console.log("Mode from context", mode);
    }, [mode]);

    useEffect(() => {
        // Simulate loading
        setTimeout(() => {
            setLoaded(true);
            setTimeout(() => {
                setAnimateText(true);
            }, 2000); // Delay to match the sphere animation
        }, 1000); // Adjust this delay as needed
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setShootingStars((stars) => [...stars, <RandomShootingStar key={stars.length} />]);
        }, Math.random() * 5000 + 2000); // Random interval between 2s and 7s

        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Ensure vertical centering of children
            alignItems: 'center', // Center children horizontally
            width: '100%',
            height: '100vh', // Full viewport height
            position: 'relative',
            overflow: 'hidden', // Hide overflowing content
            paddingTop: '48px', // Optional: adjust padding to shift content down if needed
            boxSizing: 'border-box' // Ensures padding is included in height calculation
        }}>    
            <HomePanel>
                {loaded && (
                    <SphereContainer>
                        <WireframeSphere colorToken="background.default" />
                    </SphereContainer>
                )}

                <AnimatedCloudLeft />

                <Box sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100vh',
                    overflow: 'hidden'
                }}>
                    <TwinklingStars count={80} />
                    {shootingStars}
                    {/* Other components */}
                </Box>

                <Box sx={{
                    width: '874px',
                    height: 'auto',
                    textAlign: 'center',
                }}>
                    <FitTypography text="When I am working on a problem" variant="display1" color={color} lineHeight={2.2} style={{
                        opacity: animateText ? 1 : 0,
                        transform: animateText ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                        transitionDelay: '2s' // Adjust delay to stagger animations
                    }} />
                    <FitTypography text="I never think about beauty" variant="display2" color={color} lineHeight={1.2} highlight={['never think']} highlightColor={highlightColor} style={{
                        opacity: animateText ? 1 : 0,
                        transform: animateText ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                        transitionDelay: '2.6s' // Adjust delay to stagger animations
                    }} />
                    <FitTypography text="But when I have finished" variant="display1" color={color} lineHeight={1.8} style={{
                        opacity: animateText ? 1 : 0,
                        transform: animateText ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                        transitionDelay: '4s' // Adjust delay to stagger animations
                    }} />
                    <FitTypography text="If the solution is not beautiful" variant="display3" color={color} lineHeight={1.5} highlight={['beautiful']} highlightColor={highlightColor} style={{
                        opacity: animateText ? 1 : 0,
                        transform: animateText ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                        transitionDelay: '4.6s' // Adjust delay to stagger animations
                    }} />
                    <FitTypography text="I know it is wrong" variant="display2" color={color} lineHeight={1.4} style={{
                        opacity: animateText ? 1 : 0,
                        transform: animateText ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 1s ease-in-out, transform 1s ease-in-out',
                        transitionDelay: '6s' // Adjust delay to stagger animations
                    }} />
                    <StaggeredFadeTypography
                        text="— Buckminster Fuller"
                        variant="captionLarge"
                        color={theme.palette.background.paper}
                        lineHeight={1.4}
                        spacing={4}
                        initialDelay={10}  // Delay the start of the animation
                        style={{
                            fontFamily: 'Raleway, sans-serif',
                            fontStyle: 'italic',
                            fontWeight: 700,
                            letterSpacing: '0.1em',
                            textAlign: 'center'
                        }}
                    />
                </Box>
                
            </HomePanel>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100vh',
                position: 'relative',
                overflow: 'hidden' // This will hide any overflowing content
            }}>     
                <CloudFromRight src={cloud2} alt="Cloud2" />
            </Box>
        </Box>
    );
};

export default HomePage;
