// src/components/ContentPanel.js

import React from 'react';
import { Box } from '@mui/material';

const ContentPanel = ({ children }) => {
    return (
        <Box
            component="main"
            sx={{
                top: 0, // Align to the top of the parent
                left: 0, // Align to the left of the parent
                width: '100%', // Span the full width
                height: '100vh', // Span the full height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start', // Align content to the top
                alignItems: 'center', // Center content horizontally
                bgcolor: 'background.paper', // theme token for dark background
                overflowY: 'auto' // Allow vertical scrolling
            }}
        >
        {children}
    </Box>
    );
};

export default ContentPanel;
