import React, { useEffect, useState, useRef } from 'react';
import { Drawer, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AutoFitTypography from './AutoFitTypography';  // Import the new component

const LeftMenuDrawer = () => {
    const theme = useTheme();
    const drawerWidth = 304; // Adjust as needed

    const currentYear = new Date().getFullYear();
    const chineseZodiacYear = "Dragon"; // This should be dynamically calculated if needed

    const [isMounted, setIsMounted] = useState(false);
    const [reRender, setReRender] = useState(false);
    const fitTypographyRefs = useRef([]);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            console.log('LeftMenuDrawer mounted');
            // Delay the check for FitTypography elements
            setTimeout(() => {
                fitTypographyRefs.current.forEach((el) => {
                    if (el) {
                        console.log('FitTypography element:', el);
                        console.log('Element dimensions:', el.getBoundingClientRect());
                    }
                });
                // Force a re-render to ensure correct sizing
                setReRender(true);
            }, 100); // Adjust the timeout duration as needed
        }
    }, [isMounted]);

    useEffect(() => {
        if (reRender) {
            fitTypographyRefs.current.forEach((el) => {
                if (el) {
                    console.log('Re-rendered FitTypography element:', el);
                    console.log('Re-rendered element dimensions:', el.getBoundingClientRect());
                }
            });
        }
    }, [reRender]);

    if (!isMounted) {
        return null; // or a loading spinner
    }

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'background.paper', borderRight: 'none' }
            }}
        >
            <Box sx={{ display: 'flex',  alignItems: 'center', flexDirection: 'column', height: '100%', position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: '40px 16px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 1 }}>
                            <Box sx={{ width: '24px', height: '2px', bgcolor: theme.palette.secondary.dark, mb: 0.5 }} />
                            <Box sx={{ width: '16px', height: '2px', bgcolor: theme.palette.secondary.dark, mb: 0.5 }} />
                            <Box sx={{ width: '24px', height: '2px', bgcolor: theme.palette.secondary.dark }} />
                        </Box>
                        <Box sx={{ 
                            position: 'relative',
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            padding: '1px 8px 0px', 
                            backgroundColor: alpha(theme.palette.primary.dark, 0.8), 
                            color: theme.palette.background.default,
                            width: 'auto',
                            height: 'auto',
                            '&::before, &::after': {
                                content: '""',
                                position: 'absolute',
                                width: 0,
                                borderLeft: '12px solid transparent',
                                borderRight: '12px solid transparent',
                            },
                            '&::before': {
                                top: '-6px',
                                borderBottom: `6px solid ${alpha(theme.palette.primary.dark, 0.8)}`,
                            },
                            '&::after': {
                                bottom: '-6px',
                                borderTop: `6px solid ${alpha(theme.palette.primary.dark, 0.8)}`,
                            },
                            '&::before': {
                                left: '-6px',
                                borderRight: `6px solid ${alpha(theme.palette.primary.dark, 0.8)}`,
                            },
                            '&::after': {
                                right: '-6px',
                                borderLeft: `6px solid ${alpha(theme.palette.primary.dark, 0.8)}`,
                            }
                        }}>
                            <Typography sx={{ fontFamily: 'Bungee', fontSize: '14px', color: theme.palette.background.paper }}>
                                {currentYear} • {chineseZodiacYear}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 1 }}>
                            <Box sx={{ width: '24px', height: '2px', bgcolor: theme.palette.secondary.dark, mb: 0.5 }} />
                            <Box sx={{ width: '16px', height: '2px', bgcolor: theme.palette.secondary.dark, mb: 0.5 }} />
                            <Box sx={{ width: '24px', height: '2px', bgcolor: theme.palette.secondary.dark }} />
                        </Box>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <Box mb={1} sx={{ width: '216px', height: 'auto' }}>
                            <AutoFitTypography 
                                lines={['Nathan', 'Henry']}  // Lines for the logo text
                                targetWidth={216}  // Adjust to match your specific needs
                                lineHeight={1.0}  // Set the desired line height
                                variant="display1"  // Use Display1 typography variant
                                sx={{ color: theme.palette.secondary.dark, letterSpacing: '-1px', textTransform: 'uppercase' }}
                            />
                        </Box>

                        <Box sx={{ width: '216px', height: 'auto', overflow: 'hidden' }}>
                            <AutoFitTypography 
                                lines={['Creative Technologist']}  // Line for the job title
                                targetWidth={196}  // Adjust to match your specific needs
                                lineHeight={1.0}  // Set the desired line height
                                variant="display4"  // Use Display4 typography variant
                                sx={{ color: theme.palette.primary.dark, textTransform: 'uppercase', letterSpacing: '1px'}}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ position: 'absolute', top: '50%', left: 0, right: 0, transform: 'translateY(-50%)', padding: '24px' }}>
                    <List>
                        <ListItem component="a" href="tel:+17185946046" sx={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItemIcon sx={{ minWidth: '48px' }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: '32px', 
                                    height: '32px', 
                                    borderRadius: '50%', 
                                    backgroundColor: theme.palette.neutral.main, 
                                    color: theme.palette.neutral.light 
                                }}>
                                    <PhoneIcon />
                                </Box>
                            </ListItemIcon>
                            <ListItemText 
                                primary="+1.718.594.6046"
                                primaryTypographyProps={{ fontWeight: 500, fontSize: '0.875rem' }}
                            />
                        </ListItem>
                        <ListItem component="a" href="mailto:nathan@nathanhenry.design" sx={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItemIcon sx={{ minWidth: '48px' }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: '32px', 
                                    height: '32px', 
                                    borderRadius: '50%',
                                    backgroundColor: theme.palette.neutral.main, 
                                    color: theme.palette.neutral.light 
                                }}>
                                    <MailIcon/>
                                </Box>
                            </ListItemIcon>
                            <ListItemText 
                                primary="nathan@nathanhenry.design"
                                primaryTypographyProps={{ fontWeight: 500, fontSize: '0.875rem' }}
                            />
                        </ListItem>
                        <ListItem component="a" href="https://www.linkedin.com/in/nathanohenry" target="_blank" sx={{ textDecoration: 'none', color: 'inherit' }}>
                            <ListItemIcon sx={{ minWidth: '48px' }}>
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    width: '32px', 
                                    height: '32px', 
                                    borderRadius: '50%', 
                                    backgroundColor: theme.palette.neutral.main, 
                                    color: theme.palette.neutral.light 
                                }}>
                                    <LinkedInIcon />
                                </Box>
                            </ListItemIcon>
                            <ListItemText 
                                primary="LinkedIn"
                                primaryTypographyProps={{ fontWeight: 500, fontSize: '0.875rem' }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </Drawer>
    );
};

export default LeftMenuDrawer;