import React, { useContext } from 'react';
import { AppBar, Toolbar, Button, IconButton, Box, useTheme } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Icon for dark mode
import { ColorModeContext } from '../../styles/theme'; // Adjusted path to theme.js
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import HomeIcon from '@mui/icons-material/Home'; // Import Home icon

const TopMenu = () => {
    const theme = useTheme(); // Access theme
    const { toggleColorMode } = useContext(ColorModeContext);

    return (
        <AppBar position="fixed" color="transparent" elevation={0} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
            <Toolbar sx={{ justifyContent: 'flex-end' }}>
                <Box sx={{ color: theme.palette.background.default }}>
                    {/* Populate with links */}
                    
                    <IconButton component={Link} to="/" color="inherit">
                        <HomeIcon />
                    </IconButton>                 
                    <Button component={Link} to="/resume" color="inherit">Resume</Button>
                    {/* <Button color="inherit">Contact</Button> */}
                    <IconButton onClick={toggleColorMode} color="inherit">
                        <Brightness4Icon /> 
                    </IconButton> 
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default TopMenu;